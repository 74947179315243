<template>
  <div>
    <div v-if="loadingFormData" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('COMPANY')})  | capitalize }}</strong>
    </div>
    <b-card bg-variant="dark" text-variant="light" v-else :title="$t('FORMS_GENERAL_DATA_TITLE') | capitalize">
      <b-card-text>
        <pre v-if="debug">{{ form }}</pre>
        <b-form @submit.prevent="save">
          <!-- name text input -->
          <b-form-group
              id="name-form-group"
              :label="$t('COMPANY_NAME_LABEL') | capitalize"
              label-for="name-input"
              :state="state('name')">
            <b-form-input class="w-100 ml-1" id="name-input" type="text"
                          v-model="$v.form.name.$model"
                          :state="state('name')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="name-input-feedback">
              <div v-for="error in errors('name')" :key="error.error">
                {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('COMPANY_NAME_LABEL')},})  | capitalize }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- name text input -->

          <!-- razonSocial text input -->
          <b-form-group
              id="razon-social-form-group"
              :label="$t('COMPANY_RAZON_SOCIAL_LABEL') | capitalize"
              label-for="razonSocial-input"
              :state="state('razonSocial')">
            <b-form-input class="w-100 ml-1" id="razon-social-input" type="text"
                          v-model="$v.form.razonSocial.$model"
                          :state="state('razonSocial')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="razon-social-input-feedback">
              <div v-for="error in errors('razonSocial')" :key="error.error">
                {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('COMPANY_RAZON_SOCIAL_LABEL')},})  | capitalize }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- razonSocial text input -->

          <!-- rfc text input -->
          <b-form-group
              id="rfc-form-group"
              :label="$t('COMPANY_RFC_LABEL') | capitalize"
              label-for="rfc-input"
              :state="state('rfc')">
            <b-form-input class="w-100 ml-1" id="rfc-input" type="text"
                          v-model="$v.form.rfc.$model"
                          :state="state('rfc')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="rfc-input-feedback">
              <div v-for="error in errors('rfc')" :key="error.error">
                {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('COMPANY_RFC_LABEL')},})  | capitalize }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- rfc text input -->

          <!-- address text input -->
          <b-form-group
              id="address-form-group"
              :label="$t('COMPANY_ADDRESS_LABEL') | capitalize"
              label-for="address-input"
              :state="state('address')">
            <b-form-input class="w-100 ml-1" id="address-input" type="text"
                          v-model="$v.form.address.$model"
                          :state="state('address')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="address-input-feedback">
              <div v-for="error in errors('address')" :key="error.error">
                {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('COMPANY_ADDRESS_LABEL')},})  | capitalize }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- address text input -->
          <b-button class="mt-2" type="submit" :disabled="saving" variant="primary">
            <b-icon v-if="saving" icon="circle-fill" animation="throb" class="mr-2"></b-icon>
            <span v-if="saving">{{ $t('FORMS_SAVING_LABEL')  | capitalize }}</span>
            <span v-else>{{ $t('FORMS_SAVE_LABEL')  | capitalize }}</span>
          </b-button>
        </b-form>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {required, minLength, maxLength, helpers} from "vuelidate/lib/validators";
import {Form, ProcessWithLoadingAndMessage} from "@/mixins";
import Company from "@/companies";
import Constants from "@/constants";

const regex = helpers.regex('regex', /^([A-ZÑ&]{3,4}) ?(?:- ?)?(d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]d|3[01])) ?(?:- ?)?([A-Zd]{2})([Ad])$/);

export default {
  name: "CompanyForm",
  components: {},
  mixins: [Form, ProcessWithLoadingAndMessage],
  methods: {
    async doSave(id, data) {
      return id ? await Company.update(id, data) : await Company.create(data);
    },
    getData() {
      this.loadingFormData = true;
      Company.findById(this.id)
          .then(resp => {
            this.form.name = resp.data.name;
            this.form.razonSocial = resp.data.razonSocial;
            this.form.rfc = resp.data.rfc;
            this.form.address = resp.data.address;
            this.form.turns = resp.data.turns;
            this.form.routes = resp.data.routes;
            this.form.drivers = resp.data.drivers;
            this.form.dieselControl = resp.data.dieselControl;
            this.form.travels = resp.data.travels;

          })
          .catch(e => {
            if (Constants.DEBUG) {
              console.error(e);
            }
            this.sendError('%loadingFormDataErrorMessage%', e);
          })
          .finally(() => {
            this.loadingFormData = false;
          });
    }
  },
  data() {
    return {
      loadingFormData: false,
      originals: {},
      form: {
        name: null,
        razonSocial: null,
        rfc: null,
        address: null,
        turns: null,
        routes: null,
        drivers: null,
        dieselControl: null,
        travels: null
      },
      editPage: 'COMPANY_EDIT_TITLE'
    }
  },
  validations: {
    form: {
      name: {required, minLength: minLength(4), maxLength: maxLength(200)},
      razonSocial: {minLength: minLength(4), maxLength: maxLength(400)},
      rfc: {minLength: minLength(12), pattern: regex(), maxLength: maxLength(13)},
      address: {minLength: minLength(4), maxLength: maxLength(400)},
      turns: {},
      routes: {},
      drivers: {},
      dieselControl: {},
      travels: {}
    }
  }
}
</script>

<style scoped>

</style>
